import { CCApiService } from "./api-service";
import { SignalCounts, UIConfig } from "./interfaces";

export class MetricsStoreService {
  static ApiService: CCApiService;
  static apiBasePath: string;
  static customerId: string;
  static analyticsApi: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.analyticsApi = `${this.apiBasePath}/analytics`;
    this.customerId = customerId;
  }

  static async getSignalSize(appId: string): Promise<any> {
    const fetchOptions = {
      method: 'GET'
    };
    return this.ApiService.performFetch(
      `${this.analyticsApi}/${this.customerId}/${appId}/signals/size`, fetchOptions).then(res => res);
  }

  static async getSignalCounts(appId: string): Promise<SignalCounts> {
    const fetchOptions = {
      method: 'GET'
    };

    return this.ApiService.performFetch(
      `${this.analyticsApi}/${this.customerId}/${appId}/signals/count`, fetchOptions).then(res => res);
  }

  static async getSignalUsage(appId: string, config): Promise<any> {
    const fetchOptions = {
      method: 'GET'
    };

    const queryParams = {
      granularity: 'auto',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...config
    }

    return this.ApiService.performFetch(
      `${this.analyticsApi}/${this.customerId}/${appId}/signals/usage?${new URLSearchParams(queryParams)}`, fetchOptions).then(res => res);
  }
}